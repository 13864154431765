const brand = {
  key: 'stDupont',
  path: 'st-dupont',
  name: 'S.T. Dupont',
  accessKey: process.env.REACT_APP_ST_DUPONT_ACCESS_KEY,
  website: {
    url: 'https://fr.st-dupont.com',
    domain: 'StDupont.com',
  },
  features: {
    withReferenceInput: true,
    withUidCheck: false,
  },
  images: {
    logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1712133422/certificate-request-page/brands/s.t%20dupont/logo_urglli.svg',
    homePage: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1716796243/Photos/ST%20Dupont/N.V_9_1_sroc6l.png',
    homePageCompressed:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1716796243/Photos/ST%20Dupont/N.V_9_1_sroc6l.png',
    certificateExample:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1690542109/certificate-request-page/brands/s.t%20dupont/certificate.png',
    certificateExampleCompressed:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1690542235/certificate-request-page/brands/s.t%20dupont/compressed-certificate.png',
    favicon:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1690542028/certificate-request-page/brands/s.t%20dupont/favicon.ico',
    uidExample: null,
    whereReference: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1735920081/st_dupont_gl5xwm.png',
  },
  socialLinks: {
    facebook: 'https://www.facebook.com/STDupontOfficial',
    instagram: 'https://www.instagram.com/stdupont',
    linkedin: 'https://www.linkedin.com/company/st-dupont',
  },
  contactUrl: {
    fr: 'https://fr.st-dupont.com/pages/nous-contacter',
    en: 'https://www.st-dupont.com/pages/contact-us',
  },
};

export default brand;
